import * as React from 'react'
import Image from 'gatsby-image'

const Encourage = ({props}) => {

  const  { encourageTitle, encourageSubtitle, encourageCopy, encourageImage } = props

  return (
    <div className="relative">
      <div className="max-w-page relative overflow-hidden">
        <div className="top-content pt-20 md:pt-22 mb-22">
          <h2 className="headline-2 text-oceanic-blue mb-4">{encourageTitle}</h2>
          <p className="body-2 w-full md:w-4/6">{encourageSubtitle}</p>
        </div>
        <div className="flex flex-col items-center lg:items-end lg:flex-row">
          <Image
            fluid={encourageImage.fluid}
            imgStyle={{objectFit: 'contain', objectPosition: 'center', height: 'auto', paddingBottom: 0 }}
            className='flex-1 w-full lg:-mb-1 md:w-2/3 z-0 lg:z-20'
            loading='eager'
            alt='Dad holding his son'
            aria-hidden
          />
          <div className="flex-1 flex items-end pb-20 pt-20 lg:pt-0 z-20 body-1 text-xl md:text-2xl">
            <p className="w-full lg:w-9/12 text-white">{encourageCopy}</p>
          </div>
        </div>
      </div>
      <div className="fatherhood-encourage-overlay absolute bottom-0 right-0 z-10 lg:z-0"></div>
    </div>
  )
}

export default Encourage