import React, { useState, useMemo, useEffect } from 'react';
import { throttle } from 'lodash';

const Sources = ({props, sources}) => {

  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = useMemo(() => throttle(() => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  }, 500), [])

  // Clean up the throttled function on component unmount
  useEffect(() => {
    return () => {
      handleToggle.cancel(); // Cancel pending throttled calls when unmounting
    };
  }, [handleToggle]);

  return (
    <div className='max-w-page'>
      <div className="py-10 md:py-14">
        <div onClick={() => handleToggle()} className="sources-btn body-4 border-b border-black py-4 font-bold flex justify-between">
          <span>{props.sourcesTitle}</span>
          <div
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 250ms ease'
            }}
            className="select-none"
          >
            <img src="/icons/arrow.svg" alt="arrow" />
          </div>
        </div>
        {sources.sources.map((item, index) => (
          <div key={index}>
            <div style={{
              maxHeight: isOpen ? '300px' : '0',
              overflow: 'hidden',
              transition: 'max-height 250ms ease, margin 250ms ease',
              marginTop: isOpen ? '24px' : '0'
              }}
            >
              <div className="py-4 px-6 border-b">
                <div className="body-4 mb-2.5 font-semibold">{index + 1}. {item.name}</div>
                <a href={item.url} target="_blank" rel="noopener noreferrer" className="font-light text-blue break-words">
                  {item.url}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Sources