import * as React from 'react';

import { useMediaCardData } from '~hooks'

import { MediaCard } from '~components/shared'

const HearFromDads = ({props}) => {

  const { hearFromDadsVideos, hearFromDadsTitle } = props

  const articles = useMediaCardData()

  let videosSection = []
  hearFromDadsVideos.forEach(article => {
    videosSection.push(articles.find(({ contentful_id }) => contentful_id === article.contentful_id))
  })

  // console.log(videosSection)
  return (
    <div className="max-w-page">
      <div className="headline-section-centered mt-22">
        <div>{hearFromDadsTitle}</div>
      </div>

      {videosSection.length > 0 && (
          <div className='flex flex-col lg:flex-row lg:justify-between lg:-mx-4 lg:mb-8'>
            {videosSection.map(({ id, image, title, caption, url, type }) => (
              <div key={id} className='flex lg:w-1/2 lg:px-4'>
                <MediaCard image={image} title={title} url={url} type={type} fatherhoodCta className='mt-8' />
              </div>
            ))}
          </div>
        )
      }
    </div>
  )
}

export default HearFromDads