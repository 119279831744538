import * as React from "react"
import Image from 'gatsby-image'

const Motivate = ({props}) => {

  const { motivateTitle, motivateImage, motivateCopy } = props

  return (
    <div className="max-w-page pt-16 pb-8 text-left lg:text-center">
      <h2 className="headline-2 text-oceanic-blue">{motivateTitle}</h2>
      <div className="my-10">
        <Image
          fixed={motivateImage.fixed}
          imgStyle={{ height: 'auto' }} 
          loading='eager'
          alt='N/A'
          aria-hidden
        />
      </div>
      <p className="body-2 lg:max-w-4xl mx-auto">{motivateCopy.motivateCopy}</p>
    </div>
  )
}

export default Motivate